import React, { useCallback, useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import "./App.scss";

function titleCaseWords(words) {
  console.log(words, words.length);
  if (words.length < 2) return words;
  return words
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(" ");
}

function Logo({ platformName }) {
  console.log("platofrm", platformName);
  if (platformName === "Moxie") {
    return (
      <img
        className="header-logo"
        alt="logo"
        src="https://assets-global.website-files.com/62d6530a61ad7106276fd746/647f9c6a506bdcbb6ae39934_Moxie%20Logotype_4f0751%20(1)-p-500.png"
      />
    );
  } else if (platformName === "MoeGo") {
    return (
      <img
        className="moego-logo"
        alt="logo"
        src="https://www.moego.pet/_next/image?url=%2F_next%2Fstatic%2Fimage%2Fpublic%2Fimages%2Flogo-colored.f7760c45eac2418e678bbddfaab1af4a.png&w=384&q=100"
      />
    );
  } else if (platformName === "Release") {
    return (
      <img
        className="release-logo"
        alt="logo"
        src="https://tryrelease.com/static/0dba9b22943ca1190bfdce0b0222a5e2/95792/releaselogo%402x.webp"
      />
    );
  } else if (platformName === "Finguard") {
    return (
      <img
        className="finguard-logo"
        alt="logo"
        src="https://finguard.layerfi.com/logo512.png"
      />
    );
  } else if (platformName === "Carry") {
    return (
      <img
        className="carry-logo"
        alt="logo"
        src="https://summit.carrymoney.com/wp-content/uploads/2023/10/carry-logo-black.svg"
      />
    );
  } else {
    return (
      <img
        className="header-logo-wide"
        alt="logo"
        src="https://uploads-ssl.webflow.com/64518d097e2de965c5c68c6d/645be36256d32fcae3bf27a0_Layer%20Logo.svg"
      />
    );
  }
}
// Creates a Link token
async function createLinkUpdateToken(
  businessId,
  itemId,
  setToken,
  setShouldOpenRelinking
) {
  // For OAuth, use previously generated Link token
  if (window.location.href.includes("?oauth_state_id=")) {
    const linkToken = localStorage.getItem("link_token");
    setToken(linkToken);
    setShouldOpenRelinking(true);
  } else {
    const response = await fetch(
      `/api/create_link_update_token?link_id=${businessId}&item_id=${itemId}`,
      {}
    );
    const data = await response.json();
    setToken(data.link_token);
    localStorage.setItem("link_token", data.link_token);
    setShouldOpenRelinking(true);
  }
}

function App(props) {
  const [token, setToken] = useState(null);
  const [data, setData] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [shouldOpenRelinking, setShouldOpenRelinking] = useState(false);

  const [loadingCustomUrl, setLoadingCustomUrl] = useState(true);

  const [platformName, setPlatformName] = useState(undefined);
  const [customerName, setCustomerName] = useState(undefined);
  //extract the public token from the URL query string
  const businessUrlToken = window.location.href.match(/\?business=([^&]*)/);
  const businessId = businessUrlToken?.at(0)?.replace("?business=", "");
  const secondaryColorClass =
    {
      Moxie: "slate-background",
      Foxie: "slate-background",
      MedPartner: "green-secondary-background",
      MoeGo: "moego-secondary-background",
      Release: "release-secondary-background",
      Finguard: "finguard-secondary-background",
      Carry: "carry-secondary-background",
    }[platformName] || "default-secondary-background";

  const textOnSecondaryColorClass =
    {
      MoeGo: "moego-secondary-section-text",
      Release: "release-secondary-section-text",
      Carry: "carry-secondary-section-text",
    }[platformName] || "default-horizontal-section-text";

  const primaryColorClass =
    {
      Moxie: "moxie-purple-primary",
      Foxie: "moxie-purple-primary",
      MedPartner: "yellow-primary-background",
      MoeGo: "moego-primary-background",
      Release: "release-primary-background",
      Finguard: "finguard-primary-background",
      Carry: "carry-primary-background",
    }[platformName] || "default-primary-background";

  const iconMaskClass =
    {
      Moxie: "mask-none",
      Foxie: "mask-none",
      MedPartner: "mask-white",
      MoeGo: "mask-grey",
      Release: "mask-grey",
      Finguard: "mask-white",
      Carry: "mask-grey",
    }[platformName] || "mask-white";

  useEffect(() => {
    async function fetchData() {
      const customPageRequest = await fetch(
        `/api/custom_link?link_id=${businessId}`,
        {}
      );
      const customPagePayload = await customPageRequest.json();
      console.log(customPagePayload);
      setPlatformName(customPagePayload.platform.name);
      setCustomerName(customPagePayload.user_name);
    }
    if (businessId !== undefined) {
      setLoadingCustomUrl(true);
      fetchData();
      setLoadingCustomUrl(false);
    }
  }, [platformName, businessId]);

  const getAccountList = React.useCallback(async () => {
    setLoading(true);
    const response = await fetch(`/api/account-list?link_id=${businessId}`, {});
    const accountsData = await response.json();
    setAccounts(accountsData);
    setLoading(false);
  }, [setAccounts]);

  useEffect(() => {
    if (businessId !== undefined) {
      getAccountList();
    }
  }, [getAccountList]);

  const onSuccess = useCallback(
    async (publicToken) => {
      setLoading(true);
      await fetch(`/api/exchange_public_token?link_id=${businessId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ public_token: publicToken }),
      });
      //await getBalance();
      await getAccountList();
    },
    [getAccountList, businessId]
  );

  // Creates a Link token
  const createLinkToken = React.useCallback(async () => {
    // For OAuth, use previously generated Link token
    if (window.location.href.includes("?oauth_state_id=")) {
      const linkToken = localStorage.getItem("link_token");
      setToken(linkToken);
    } else if (token === null) {
      const response = await fetch(
        `/api/create_link_token?link_id=${businessId}`,
        {}
      );
      const data = await response.json();
      setToken(data.link_token);
      localStorage.setItem("link_token", data.link_token);
    }
  }, [setToken, businessId, token]);

  let isOauth = false;

  const config = {
    token,
    onSuccess,
  };

  // For OAuth, configure the received redirect URI
  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (token == null && businessId !== undefined) {
      createLinkToken();
    }
  }, [token, businessId, createLinkToken]);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [isOauth, ready, open]);

  useEffect(() => {
    if (shouldOpenRelinking && ready) {
      open();
      setShouldOpenRelinking(false);
    }
  }, [shouldOpenRelinking, ready, open]);

  console.log("accounts", accounts);

  if (businessId === undefined) {
    return (
      <div className="outer-container">
        <header className="header">
          <Logo platform={platformName} />
          <img
            className="header-logo"
            src="https://assets-global.website-files.com/62d6530a61ad7106276fd746/647f9c6a506bdcbb6ae39934_Moxie%20Logotype_4f0751%20(1)-p-500.png"
          />
        </header>
        {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
        <div className="inner-container">
          {/* Content goes here */}

          <h1 className="title">Linking for Bookkeeping</h1>
          <p className="subtitle-text">
            Link external accounts, such as business banking and credit cards to
            automatically import and track expenses.
          </p>
          <p className="subtitle-text italic">
            You need a personalized link to connect your accounts. Please reach
            out to the Moxie team for help.
          </p>
          <button
            className={`${primaryColorClass}`}
            disabled
            onClick={() => open()}
          >
            <strong>Link account</strong>
          </button>
        </div>
      </div>
    );
  }

  if (loadingCustomUrl || platformName === undefined) {
    return (
      <div className="page-container">
        <h1>Loading...</h1>
        <div className="spinner" />
      </div>
    );
  }
  return (
    <>
      <link
        rel="icon"
        href="https://assets-global.website-files.com/62d6530a61ad7106276fd746/62eb0e116398e44e60c40d32_favicon-32x32.png"
      />
      <header className="header">
        <Logo platformName={platformName} />
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a
            href="/"
            className="escape-link text-sm font-semibold leading-6 text-gray-900"
          >
            Not {customerName}? <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </header>
      <div className="outer-container">
        {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
        <div className="inner-container">
          {/* Content goes here */}

          <div className="half-width-section">
            <h1 className="title">
              {!!platformName ? platformName : ""} <br />
              Bookkeeping
            </h1>
            <p className="subtitle-text">
              Link external accounts, such as business banking and credit cards
              to automatically import and track expenses.
            </p>
            <button
              className={`${primaryColorClass}`}
              onClick={() => open()}
              disabled={!ready}
            >
              <strong>Link new accounts for {customerName}</strong>
            </button>
          </div>
        </div>
        <div
          className={`${textOnSecondaryColorClass} horizontal-section ${secondaryColorClass}`}
        >
          <p className="slate-title">How it works</p>
          <div className="slate-section-inner">
            <div className="horizontal-section-inner">
              <div className="horizontal-title-section">
                <img
                  className={`icon ${iconMaskClass}`}
                  src="https://assets-global.website-files.com/62d6530a61ad7106276fd746/62d67d6dc4c0536223c61527_icon-manage.webp"
                />
                <p className="horizontal-title">Find your bank</p>
              </div>
              <p className="horizontal-section-text">
                Find the bank, credit card, or other financial account you use
                to run your practice.
              </p>
            </div>
            <div className="horizontal-section-inner">
              <div className="horizontal-title-section">
                <img
                  className={`icon ${iconMaskClass}`}
                  src="https://assets-global.website-files.com/62d6530a61ad7106276fd746/62d67d6dca4169728df32725_icon-launch.webp"
                />
                <p className="horizontal-title">Log in</p>
              </div>
              <p className="horizontal-section-text">
                Log into the account to grant read access to transaction data.
                We won't get any other personal information or control over the
                accounts.
              </p>
            </div>
            <div className="horizontal-section-inner">
              <div className="horizontal-title-section">
                <img
                  className={`icon ${iconMaskClass}`}
                  src="https://assets-global.website-files.com/62d6530a61ad7106276fd746/62d6af864810053fd9e42b62_icon-check.webp"
                />
                <p className="horizontal-title">We'll take care of the rest</p>
              </div>
              <p className="horizontal-section-text">
                We will automatically track and categorize expense activity to
                provide detailed insights into finances & profitability.
              </p>
            </div>
          </div>
        </div>
        <div className="centering">
          <h3 className="cta-text">
            {!!customerName ? `Link accounts for ${customerName}` : ""}
          </h3>
          <button
            className={`${primaryColorClass}`}
            onClick={() => open()}
            disabled={!ready}
          >
            <strong>Link new account</strong>
          </button>
          <ul className="account-list">
            {accounts.map((account, i) => (
              <li className="account-list-item" key={i}>
                <div className="account-list-left">
                  <div className="account-left-text">
                    <p className="account-name">{account.name}</p>
                    <p className="account-full-name">{account.official_name}</p>
                  </div>
                </div>

                <div className="account-list-right">
                  <p className="account-type">
                    {titleCaseWords(account.subtype)}
                  </p>
                  {account.update_required ? (
                    <div className="connected-outer">
                      <div className="broken-inner">
                        <div className="broken-dot" />
                      </div>

                      <p
                        onClick={() => {
                          createLinkUpdateToken(
                            businessId,
                            account.item_id,
                            setToken,
                            setShouldOpenRelinking
                          ).then(() => {
                            console.log(
                              "new link token",
                              token,
                              "ready?",
                              ready
                            );
                          });
                        }}
                        className="connected-text clickable-text"
                      >
                        Repair connection ›{" "}
                      </p>
                    </div>
                  ) : (
                    <div className="connected-outer">
                      <div className="connected-inner">
                        <div className="connected-dot" />
                      </div>
                      <p className="connected-text">Connected</p>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>

        {!loading &&
          data != null &&
          Object.entries(data).map((entry, i) => (
            <pre key={i}>
              <code>{JSON.stringify(entry[1], null, 2)}</code>
            </pre>
          ))}
      </div>
    </>
  );
}

export default App;
